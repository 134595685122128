const formater = val => {
  if (val === null || val === undefined || val === false) return val
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(parseFloat(val))
}

export default {
  balance (state) {
    return formater(state.balance)
  }
}
