export default {
  items (state) {
    return state.items
  },
  categoryItems (state) {
    return state.categoryItems
  },
  lastCategoryItem (state) {
    return state.lastCategoryItem
  }
}
