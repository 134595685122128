import axios from 'axios'

export default {
  async fetch ({ commit, getters }) {
    const { banks } = getters
    if (banks.length) return
    const { data } = await axios.get('banks')
    commit('SET_BANKS', data.data)
    return true
  }
}
